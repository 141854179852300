import React from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';

export const Header = () => {
    return (
        <header className='bg-dark'>
            <Navbar bg='dark' expand='lg'>
                <Container>
                    <Navbar.Brand href='/'><img src='../linear_logo.svg' alt='logo' /></Navbar.Brand>
                    <Navbar.Toggle aria-controls='navbar' />
                    <Navbar.Collapse id='navbar'>
                        <Nav className='navbar--items'>
                            <Nav.Link href='/' className='h-6 navbar--link'>O Escritório</Nav.Link>
                            <Nav.Link href='/areas' className='h-6 navbar--link'>Áreas de Atuação</Nav.Link>
                            <Nav.Link href='/profissionais' className='h-6 navbar--link'>Profissionais</Nav.Link>
                            <Nav.Link href='/artigos' className='h-6 navbar--link'>Artigos Jurídicos</Nav.Link>
                            <Nav.Link href='#footer-top' className='h-6 navbar--link'>Contato</Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </header>
    );
};
