const getAddressLine = (addressInfo) => {
    return addressInfo ? `${addressInfo.city} - ${addressInfo.stateCode} ${addressInfo.streetAddress}, ${addressInfo.district}` : null;
};

const getPhone = (phone) => {
    return phone?.replace(/\D/g, '').replace(/(\d{2})(\d)/, '($1) $2').replace(/(\d{5})(\d)/, '$1-$2').replace(/(-\d{4})\d+?$/, '$1');
};

const getDate = (date) => {
    return new Date(date)?.toLocaleString('pt-BR', {day: '2-digit', month: 'short', year: '2-digit'})?.toLowerCase().replace(/ de /g, '/').replace('.', '');
};

const getFullDate = (date) => {
    return new Date(date)?.toLocaleDateString('pt-BR', { year: 'numeric', month: 'long', day: 'numeric'});
};

const getFormattedFields = (fields) => {
    let string = fields?.join(', ').replace(/,(?=[^,]+$)/, ' e');

    return string;
};

export {
    getAddressLine,
    getPhone,
    getDate,
    getFullDate,
    getFormattedFields
};
