export class FooterDto {
    constructor(data) {
        this.address = {
            header: data?.ColunaEndereco?.Titulo,
            info: {
                streetAddress: data?.ColunaEndereco?.Endereco?.Logradouro,
                district: data?.ColunaEndereco?.Endereco?.Bairro,
                city: data?.ColunaEndereco?.Endereco?.Cidade,
                stateCode: data?.ColunaEndereco?.Endereco?.Estado,
                zipCode: data?.ColunaEndereco?.Endereco?.CEP,
                mapsLink: data?.ColunaEndereco?.Endereco?.LinkGoogleMaps,
            }
        };
        this.contact = {
            header: data?.ColunaContato?.Titulo,
            info: {
                email: data?.ColunaContato?.Contato?.Email,
                phone: data?.ColunaContato?.Contato?.Telefones.map((tel) => {
                    return {
                        number: tel.Numero,
                        isWhats: tel.Whatsapp
                    };
                }),
                availability: data?.ColunaContato?.Contato?.Disponibilidade,
            }
        };
        this.social = {
            instagram: data?.ColunaMidia.MidiasSociais?.Instagram,
            youtube: data?.ColunaMidia.MidiasSociais?.Youtube,
            linkedin: data?.ColunaMidia.MidiasSociais?.Linkedin,
            twitter: data?.ColunaMidia.MidiasSociais?.Twitter,
        };
        this.logo = data?.Logo?.data?.attributes?.url;
    }
}
