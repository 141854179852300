import React from 'react';
import Container from 'react-bootstrap/Container';
import { useQuery, gql } from '@apollo/client';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { brands, solid } from '@fortawesome/fontawesome-svg-core/import.macro';

import { Markdown } from './Markdown';
import { FooterDto } from '../dto/FooterDto';
import { getAddressLine, getPhone } from '../scripts/helpers/utils';

const FOOTER = gql`
    query GetFooterData{footer{data{attributes{ColunaEndereco{Titulo,Endereco{Bairro,Cidade,Estado,CEP,LinkGoogleMaps,Logradouro}},ColunaContato{Titulo,Contato{Telefones{Numero,Whatsapp},Disponibilidade,Email}},ColunaMidia{MidiasSociais{Instagram,Linkedin,Twitter,Youtube}},Logo{data{attributes{url,alternativeText}}}}}}}
`;

const FooterAddress = (props) => {
    const address = props.address;

    return (
        <div className='footer-column footer-column__address'>
            <h6>{address.header}</h6>
            <div><FontAwesomeIcon icon={solid('location-dot')} size='lg'  />{getAddressLine(address.info)}</div>
            {address.info?.mapsLink && (
                <a target='_blank' rel='noreferrer' href={address.info.mapsLink} className='footer-icon'><FontAwesomeIcon icon={solid('location-arrow')} size='lg' />Como chegar</a>
            )}
        </div>
    );
};

const FooterContact = (props) => {
    const contact = props.contact;
    const phone = contact.info.phone.map((tel) => {
        return {
            formatted: getPhone(tel.number),
            number: tel.number,
            isWhats: tel.isWhats
        };
    });

    return (
        <div className='footer-column footer-column__contact'>
            <h6>{contact.header}</h6>
            {phone.map((tel) => {
                return tel.isWhats ? (
                    <a key={tel.number} target='_blank' rel='noreferrer' href={`https://wa.me/55${tel.number}`}><FontAwesomeIcon icon={brands('square-whatsapp')} size='xl' />{tel.formatted}</a>
                ) : (
                    <span key={tel.number} className='no-whats'>{tel.formatted}</span>
                );
            })}
            <a href={`mailto:${contact.info.email}`}><FontAwesomeIcon icon={solid('envelope')} size='lg' />{contact.info.email}</a>
            {contact.info.availability && <Markdown text={contact.info.availability}/>}
        </div>
    );
};

const FooterLinks = () => {
    return (
        <div className='footer-column footer-column__links'>
            <h6>Menu</h6>
            <a href='/'>O Escritório</a>
            <a href='/areas'>Áreas de Atuação</a>
            <a href='/profissionais'>Profissionais</a>
            <a href='/artigos'>Artigos Jurídicos</a>
        </div>
    );
};

const FooterSocial = (props) => {
    const logo = props.logo;
    const social = props.social;

    return (
        <div className='footer-column footer-column__social'>
            {logo && <img className='logo' src={`${process.env.REACT_APP_BACKEND}${logo}`} />}
            {social && (
                <div className='social'>
                    {social.linkedin && <a className='footer-icon' href={`https://www.linkedin.com/in/${social.linkedin}`} target='_blank' rel='noreferrer'><FontAwesomeIcon icon={brands('linkedin')} size='2xl' /></a>}
                    {social.twitter && <a className='footer-icon' href={`https://www.twitter.com/${social.twitter}`} target='_blank' rel='noreferrer'><FontAwesomeIcon icon={brands('square-twitter')} size='2xl' /></a>}
                    {social.instagram && <a className='footer-icon' href={`https://www.instagram.com/${social.instagram}`} target='_blank' rel='noreferrer'><FontAwesomeIcon icon={brands('square-instagram')} size='2xl' /></a>}
                    {social.youtube && <a className='footer-icon' href={`https://www.youtube.com/${social.youtube}`} target='_blank' rel='noreferrer'><FontAwesomeIcon icon={brands('square-youtube')} size='2xl' /></a>}
                </div>
            )}
        </div>
    );
};

const FooterTop = (props) => {
    return (
        <div className='theme-light' id='footer-top'>
            <Container>
                <FooterAddress address={props.address} />
                <FooterContact contact={props.contact} />
                <FooterLinks />
                <FooterSocial logo={props.logo} social={props.social} />
            </Container>
        </div>
    );
};

const FooterBottom = () => {
    return (
        <div className='theme-dark' id='footer-bottom'>
            <Container>
                <div className='footer-links-wrapper'>
                    {/* <a href='/acessibilidade'>Acessibilidade</a> */}
                    <a href='/links'>Links Úteis</a>
                    <a href='/privacidade'>Política de Privacidade</a>
                    <a href='/termos'>Termos de Uso</a>
                </div>
                <p className='copyright'>© 2023  Evilasio Assunção Escritório de Advocacia</p>
            </Container>
        </div>
    );
};

export const Footer = () => {
    const { data } = useQuery(FOOTER);
    const footerData = new FooterDto(data?.footer?.data?.attributes);

    return (
        <footer>
            {data && (
                <FooterTop address={footerData.address} contact={footerData.contact} social={footerData.social} logo={footerData.logo} />
            )}
            <FooterBottom />
        </footer>
    );
};
