import React from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client';

/* Routes */
import routes from './routes';

/* Page Components */
import { Footer, Header } from './components';

/* Styles */
import 'react-loading-skeleton/dist/skeleton.css';
import './scss/app.scss';

/* Apollo Cache */
const cache = new InMemoryCache({
    typePolicies: {
        Query: {
            fields: {
                artigos: {
                    keyArgs: ['sort', 'filters'],
                    merge(existing, incoming) {
                        let merged = existing ? { data: existing.data.slice(0), meta:{} } : { data:[], meta:{} };
                        incoming?.data?.forEach((d) => {
                            merged.data.push(d);
                        });
                        merged.meta = incoming.meta;

                        return merged;
                    }
                }
            }
        }
    }
});

/* Apollo Client */
const client = new ApolloClient({
    uri: `${process.env.REACT_APP_BACKEND}/graphql`,
    cache: cache,
    headers: {
        authorization: `Bearer ${process.env.REACT_APP_STRAPI_API_KEY}`
    },
    defaultOptions: {
        watchQuery: {
            fetchPolicy: 'cache-first',
            nextFetchPolicy: 'cache-first'
        }
    }
});

function App() {
    const router = createBrowserRouter(routes);

    return (
        <div className="page">
            <ApolloProvider client={client}>
                <Header />
                <RouterProvider router={router} />
                <Footer />
            </ApolloProvider>
        </div>
    );

}

export default App;
