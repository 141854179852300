import React from 'react';
import loadable from '@loadable/component';

const Home = loadable(() => import('./pages/Home'), { resolveComponent: (components) => components.Home });
const Fields = loadable(() => import('./pages/Fields'), { resolveComponent: (components) => components.Fields });
const Articles = loadable(() => import('./pages/Articles'), { resolveComponent: (components) => components.Articles });
const Article = loadable(() => import('./pages/Article'), { resolveComponent: (components) => components.Article });
const Team = loadable(() => import('./pages/Team'), { resolveComponent: (components) => components.Team });
const PrivacyPolicy = loadable(() => import('./pages/PrivacyPolicy'), { resolveComponent: (components) => components.PrivacyPolicy });
const Accessibility = loadable(() => import('./pages/Accessibility'), { resolveComponent: (components) => components.Accessibility });
const Links = loadable(() => import('./pages/Links'), { resolveComponent: (components) => components.Links });
const Terms = loadable(() => import('./pages/Terms'), { resolveComponent: (components) => components.Terms });
const NotFound = loadable(() => import('./pages/NotFound'), { resolveComponent: (components) => components.NotFound });
const Lawyer = loadable(() => import('./pages/Lawyer'), { resolveComponent: (components) => components.Lawyer });

const routes = [
    {
        path: '/',
        errorElement: <NotFound />,
        children: [
            {
                index: true,
                element: <Home />
            },
            {
                path: '/areas',
                element: <Fields />
            },
            {
                path: '/artigos',
                element: <Articles />
            },
            {
                path: '/artigos/:uid',
                element: <Article />
            },
            {
                path: '/profissionais',
                element: <Team />
            },
            {
                path: '/profissionais/:uid',
                element: <Lawyer />
            },
            {
                path: '/acessibilidade',
                element: <Accessibility />
            },
            {
                path: '/links',
                element: <Links />
            },
            {
                path: '/privacidade',
                element: <PrivacyPolicy />
            },
            {
                path: '/termos',
                element: <Terms />
            }
        ]
    }
];

export default routes;
