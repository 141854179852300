import React from 'react';
import Skeleton from 'react-loading-skeleton';

export const GenericLoading = () => {
    return (
        <>
            <h1><Skeleton /></h1>
            <Skeleton count={30} />
        </>
    );
};
