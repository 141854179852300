import React from 'react';

import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';


export const Markdown = (props) => {
    return <ReactMarkdown className={props.className} remarkPlugins={[remarkGfm]} rehypePlugins={[rehypeRaw]}>{props.text}</ReactMarkdown>;
};
